.legal_title {
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  color: #f1faee;
  grid-column: 1/6;
  grid-row: 1/2;
}
@media (max-width: 1300px) {
  .legal_title {
    margin: 24px 60px;
  }
}
@media (max-width: 500px) {
  .legal_title {
    margin: 24px 30px;
    font-size: 20px;
  }
}
.legal_list {
  list-style-type: none;
  grid-column: 1/6;
  grid-row: 2/3;
  padding: 0;
}
.legal_list li {
  margin-top: 20px;
  font-size: 20px;
}
@media (max-width: 1300px) {
  .legal_list {
    margin: 24px 60px;
  }
}
@media (max-width: 500px) {
  .legal_list {
    margin: 10px 30px;
  }
}
.legal_link {
  font-family: "Oswald", sans-serif;
  text-decoration: none;
  color: #f1faee;
  list-style: none;
}
.legal_link:hover {
  border-bottom: 1px solid;
  transition: all 0.2s ease-out;
}

.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  width: 80%;
}

.doc-style {
  font-family: sans-serif;
  color: #f1faee;
  padding: 2%;
  line-height: 1.5rem;
}
.doc-style a {
  color: #f1faee;
}
.doc-style a:hover {
  cursor: pointer;
}

.doc-title, .doc-title_main {
  text-align: center;
  font-weight: bold;
}

.doc-title_main {
  font-size: 24px;
}

