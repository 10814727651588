.card-wrapper {
  grid-column: 3/5;
  grid-row: 2/5;
  display: flex;
  justify-content: space-around;
}
@media (max-width: 834px) {
  .card-wrapper {
    grid-row: 4/5;
    grid-column: 2/4;
    justify-content: space-between;
  }
}
@media (max-width: 570px) {
  .card-wrapper {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 474px) {
  .card-wrapper {
    grid-column: 1/2;
    grid-row: 7/8;
  }
}

.card {
  max-width: 40%;
  min-width: 210px;
  display: flex;
  flex-direction: column;
  height: 364px;
  text-align: center;
  align-items: center;
  padding: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-position: top center;
  cursor: pointer;
  color: #f1faee;
  font-family: "Oswald", sans-serif;
}
.card:hover {
  transition: all 0.1s ease-out;
}
.card:hover p {
  filter: drop-shadow(1px 1px rgb(57, 57, 57));
}
@media (max-width: 834px) {
  .card {
    max-width: 45%;
  }
}
@media (max-width: 570px) {
  .card {
    max-width: 60%;
    margin-bottom: 20px;
  }
}
.card_main {
  height: 90%;
  display: flex;
  align-items: center;
  font-size: 30px;
}
.card_bottom {
  font-size: 20px;
}
.card_triathlon {
  background-image: url(../../pic/swimCard.png), linear-gradient(rgba(0, 0, 0, 0), rgb(0, 0, 0));
}
.card_triathlon:hover {
  background-image: url(../../pic/swimCard.png);
}
.card_multisport {
  background: url(../../pic/runCard.png), linear-gradient(rgba(0, 0, 0, 0), rgb(0, 0, 0));
}
.card_multisport:hover {
  background: url(../../pic/runCard.png), rgba(36, 36, 36, 0.25);
}


