@import "../../styles/variables/variable.sass"

.legal
    &_title
        font-family: $mainFont
        font-size: 24px
        color: $white
        grid-column: 1/6
        grid-row: 1/2
        @media (max-width: 1300px)
            margin: 24px 60px
        @media (max-width: 500px)
            margin: 24px 30px
            font-size: 20px
    &_list
        list-style-type: none
        grid-column: 1/6
        grid-row: 2/3
        padding: 0
        li
            margin-top: 20px
            font-size: 20px
        @media (max-width: 1300px)
            margin: 24px 60px
        @media (max-width: 500px)
            margin: 10px 30px
            
    &_link
        font-family: $mainFont
        text-decoration: none
        color: $white
        list-style: none
        &:hover
            border-bottom: 1px solid
            transition: all 0.2s ease-out

.main-content
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    @media (max-width: 500px)
        
.container
    width: 80%

.doc-style
    font-family: sans-serif
    color: $white
    padding: 2%
    line-height: 1.5rem
    a
        color: $white
        &:hover
            cursor: pointer
.doc-title, .doc-title_main
    text-align: center
    font-weight: bold
.doc-title_main
        font-size: 24px