.menu.active {
  transform: translateY(0);
  position: fixed;
}

.menu {
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-self: center;
  margin: 0;
  padding: 0;
  width: 650px;
}
@media (max-width: 850px) {
  .menu {
    width: 70vw;
  }
}
@media (max-width: 500px) {
  .menu {
    position: absolute;
    width: 100vw;
    height: 320px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background: rgb(29, 29, 29);
    top: -20px;
    left: 0;
    transform: translateY(-120%);
    transition: all 0.2s ease-out;
    padding-bottom: 10px;
  }
}
.menu li {
  font-family: "VT323", sans-serif;
  color: rgb(173, 255, 0);
  font-size: 30px;
}
@media (max-width: 740px) {
  .menu li {
    font-size: 25px;
  }
}
@media (max-width: 500px) {
  .menu li {
    margin: 15px;
    font-size: 30px;
  }
}
.menu li a {
  color: rgb(173, 255, 0);
  text-decoration: none;
}
@media (min-width: 501px) {
  .menu li:hover {
    transition: all 0.1s ease-out;
    cursor: pointer;
    filter: drop-shadow(2px 1px 0px #f1faee);
  }
}


