.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s all;
  font-family: "Oswald", sans-serif;
  z-index: 2;
}
.modal p {
  margin: 0;
}
.modal_close {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  position: relative;
  background: rgba(29, 29, 29, 0.5);
  border-radius: 20px;
  margin-top: 10px;
  margin-right: 10px;
}
@media (max-width: 440px) {
  .modal_close {
    margin-top: 5px;
    margin-right: 5px;
  }
}
.modal_close span:last-child {
  transform: rotate(135deg);
}
.modal_close span {
  position: absolute;
  display: flex;
  width: 28px;
  height: 4px;
  background: rgb(173, 255, 0);
  transform: rotate(45deg);
  translate: 0 18px;
}
.modal_close:hover {
  cursor: pointer;
}
.modal_content {
  width: 50vw;
  border-radius: 5px;
  background-color: white;
  transform: scale(0.8);
  transition: 0.1s all;
  background: rgb(29, 29, 29);
  color: #f1faee;
}
@media (max-width: 1000px) {
  .modal_content {
    width: 80vw;
  }
}
@media (max-width: 400px) {
  .modal_content {
    width: 95vw;
  }
}
.modal_content.active {
  transform: scale(1);
}
.modal_content_header {
  display: flex;
  height: 150px;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-end;
  background-blend-mode: multiply;
  margin-bottom: 22px;
  flex-direction: column;
}
.modal_content_header.triathlon {
  background: center/cover no-repeat url(../pic/triathlon-card-background.png), rgba(36, 36, 36, 0.25);
}
.modal_content_header.multisport {
  background: center/cover no-repeat url(../pic/multisport-card-background.png), rgba(36, 36, 36, 0.25);
}
.modal_content_header p {
  padding-top: 20px;
  font-size: 40px;
  color: #f1faee;
  align-self: center;
  filter: drop-shadow(1px 1px rgb(57, 57, 57));
}
.modal_content_tumbler {
  display: flex;
  justify-content: center;
  font-family: "VT323", sans-serif;
  margin-bottom: 22px;
}
.modal_content_tumbler_el {
  border: 1px rgb(173, 255, 0) solid;
  font-size: 25px;
  width: 65px;
  text-align: center;
  cursor: pointer;
}
.modal_content_tumbler_el.active {
  background: rgb(173, 255, 0);
  color: #000;
}
.modal_content_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal_content_description {
  margin-bottom: 22px;
  width: 95%;
  text-align: center;
}
.modal_content_advantages {
  width: 350px;
  min-height: 215px;
}
@media (max-width: 440px) {
  .modal_content_advantages {
    width: 95%;
  }
}
.modal_content_advantages p {
  text-align: center;
  color: rgb(173, 255, 0);
}
.modal_content_advantages li {
  padding-bottom: 5px;
}
.modal_content_price {
  color: rgb(173, 255, 0);
  font-size: 30px;
}
.modal_content_acceptPolicy {
  display: flex;
  height: 85px;
  align-items: center;
  align-content: space-around;
  width: 95%;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0;
}
.modal_content_acceptPolicy div {
  text-align: center;
}
.modal_content_acceptPolicy label, .modal_content_acceptPolicy input {
  cursor: pointer;
}
.modal_content_acceptPolicy input {
  margin-right: 8px;
}
.modal_content_acceptPolicy a {
  color: #f1faee;
}
.modal_content_button {
  width: 200px;
  height: 55px;
  padding: 0;
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  margin-bottom: 20px;
  border: none;
  background: rgb(57, 57, 57);
  cursor: not-allowed;
}
@media (max-width: 250px) {
  .modal_content_button {
    width: 90%;
  }
}
.modal_content_button.active {
  background: rgb(173, 255, 0);
  cursor: pointer;
}
.modal.active {
  opacity: 1;
  pointer-events: all;
}

