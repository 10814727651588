@import ../../styles/variables/variable
    
.card
    max-width: 40%
    min-width: 210px
    display: flex
    flex-direction: column
    height: 364px
    text-align: center
    align-items: center
    padding: 5px
    background-size: cover
    background-repeat: no-repeat
    background-blend-mode: multiply
    background-position: top center
    cursor: pointer
    color: $white
    font-family: $mainFont
    &:hover
        transition: all 0.1s ease-out
        p
            filter: drop-shadow(1px 1px $footerFontColor)
    @media (max-width:834px)
        max-width: 45%
    @media (max-width:570px)
        max-width: 60%
        margin-bottom: 20px
    &_main
        height: 90%
        display: flex
        align-items: center
        font-size: 30px
    &_bottom
        font-size: 20px
    &_triathlon
        background: url(../../pic/swimCard.png),linear-gradient(#00000000, rgb(0, 0, 0))
        &:hover
            background: url(../../pic/swimCard.png)
    &_multisport
        background: url(../../pic/runCard.png),linear-gradient(#00000000, rgb(0, 0, 0))
        &:hover
            background: url(../../pic/runCard.png), rgba(36, 36, 36, 0.25)

.subCard
    background-color: rgba(29, 29, 29, 1)
    width: 50%
    height: 75px
    justify-content: center
    &_main
        font-size: 30px
        &_title
            margin: 0