@import ../../styles/variables/variable

.card-wrapper
    grid-column: 3/5
    grid-row: 2/5
    display: flex
    justify-content: space-around
    @media (max-width:834px)
        grid-row: 4/5
        grid-column: 2/4
        justify-content: space-between
    @media (max-width:570px)
        flex-direction: column
        align-items: center
    @media (max-width:474px)
        grid-column: 1/2
        grid-row: 7/8

        
    