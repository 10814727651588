@import ../../styles/variables/variable

nav
    display: flex
    flex-direction: column
    justify-content: space-around
    z-index: 2
    padding-top: 20px
    margin-bottom: 30px
    @media (max-width:500px)
        background: #1d1d1d
        justify-content: center
        width: 100%
        padding: 0
        height: 50px
        flex-direction: row
        justify-content: space-between
        align-items: center
        position: fixed
        margin: 0
    &.wrapper
        display: flex
        align-items: center
        width: 100%
.header-back
    display: flex
    justify-content: center
    width: 300px
    @media (max-width:500px)
        width: 120px
        margin:0 5px
        justify-content: flex-end
    button
        font-family: $secondFont
        font-size: 30px
        color: $green
        background: none
        background: $footerGrey
        &:last-child
            margin-left: 25px
            @media (max-width:500px)
                margin-left: 5px
        &:hover
            cursor: pointer
        @media (max-width:500px)
            font-size: 24px
.title
    text-align: center
    margin-bottom: 30px
    @media (max-width: 500px)
        font-size: 25px
        margin:0 5px
        text-align: left
        vertical-align: middle
        z-index: 2
.burger
    display: none
    @media (max-width: 500px)
        z-index: 2
        height: 20px
        display: flex
        flex-direction: column
        justify-content: space-between
        margin-right: 5px
        span
            width: 32px
            height: 4px
            background: $green
.burger.close
    position: relative
    top: 5px
    right: 7px
    rotate: 45deg
    span
        width: 28px
    span:last-child
        visibility: hidden
    span:first-child
        position: absolute
        rotate: 90deg