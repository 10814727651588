@import ../../styles/variables/variable

.footer
    width: 100%
    background: #1d1d1d
    height: 200px
    align-items: center
    display: flex
    justify-content: center
    font-style: normal
    font-family: $secondFont
    color: $footerFontColor
    font-size: 24px
    align-self: flex-end
    @media (max-width:474px)
        height:270px
    &_block
        height: 130px
        margin: 0 10px
        @media (max-width:474px)
            height:100%
    &_social
        grid-column: 1/3
        display: flex
        flex-direction: column
        justify-content: space-between
        @media (max-width:474px)
            grid-column: 1/2
            grid-row: 1/2
            height: 140px
            justify-content: space-around
    &_contact
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        @media (max-width:474px)
            justify-content: space-evenly
            height: 75px
        a
            text-decoration: none
            margin-right: 10px
            color: $footerFontColor
            @media (min-width:835px)
                &:hover
                    transition: all 0.2s ease-out
                    color:$white
        &_mail
            display: flex
            align-items: center
            justify-content: space-between
            width: 230px
            &:hover
                transition: all 0.2s ease-out
                color:$white
    &_title
        color: $footerFontColor
        font-size: 40px
        @media (max-width:474px)
            text-align: center
            font-size: 30px
    &_right-side
        margin-top: 12px
        grid-column: 4/6
        display: flex
        align-items: flex-end
        justify-content: space-between
        flex-direction: column
        @media (max-width:834px)
            grid-column: 3/5
        @media (max-width:474px)
            justify-content: flex-end
            grid-column: 1/2
            grid-row: 2/3
            justify-self: center
            width: 230px
            height: 105px
            align-items: center
            margin-top:0
        &_wrapper
            display: flex
            align-items: flex-end
            flex-direction: column
            @media (max-width:474px)
                align-items: center
        a
            text-decoration: none
            color: inherit
            
.doc, .doc_title
    font-family: $mainFont
    font-size: 20px
    color: $footerFontColor
    margin: 0
    @media (max-width:474px)
        font-size: 18px
.doc
    text-decoration: none
    &:hover
        transition: all 0.2s ease-out
        color:$white
    &_title
        text-align: end
        @media (max-width:474px)
            text-align: center
            padding-bottom: 5px

        