@import '../../../../styles/variables/variable.sass'
@import url('https://fonts.googleapis.com/css2?family=Reddit+Mono:wght@200..900&family=Share+Tech+Mono&display=swap')
    

.timer
    margin: 100px 2%
    padding: 20px 0
    max-width: 400px
    display: flex
    align-items: center
    flex-direction: column
    background: #1d1d1d
    border-radius: 5px
    
.group
    &_list
        border: 2px solid
        border-radius: 5px
        font-family: "Reddit Mono", monospace
        color: #b3b3b3
        font-size: 15px
        margin-bottom: 2px
        & p
            margin: 0
            padding: 2%
    &_wrapper
        display: flex
        justify-content: space-between
        padding: 5px 5px 0
    &_button
        display: flex
        height: 35px
        align-items: center
        justify-content: center
        width: 60px
        border-radius: 5px
        border: 1px solid
        background: $white
        color: $footerGrey
    &_name
        width: 100%
        display: flex
        flex-direction: row
        align-items: center
    &_item
        width: 80px 


.input
    &_wrapper
        margin-bottom: 30px
        display: flex
        flex-direction: column
        align-items: center
    &_block
        height: 50px
        display: flex
        justify-content: center
        align-items: center
    &_group
        width: 8%
    &_name
        width: 60%
    &_group,&_name
        height: 30px
        font-size: 20px
        border: 0
        margin: 0 1px
        padding: 0
        border-radius: 5px

.button
    height: 30px
    width: 50px
    border: 1px solid
    padding: 0
    border-radius: 5px
    margin: 0 2px
    transform: none
    filter: drop-shadow(2px 2px 0px $green)
    font-family: $mainFont
    color: #161616
    &:active
        transform: translate(2px, 2px)
        filter: none
        transition: all 0.1s ease-out
    @media (min-width: 360px)
        width: 60px
    &_block 
        display: flex
        justify-content: center
        margin-bottom: 40px
    &_lap
        margin-right: 5px 
    &_lastLap
        justify-self: flex-end
    &_add
        width: 120px

.stopwatch
    display: flex
    flex-direction: column
    padding: 0 2% 2%

    &_wrapper
        display: grid
        grid-template-columns: auto auto
        grid-template-rows: auto



.display
    font-size: 70px
    font-family: "Reddit Mono", monospace
    grid-column: 1/3
    justify-content: center
    justify-self: center
    color: $green
    margin-bottom: 10px
    &_user
        height: 38px
        width: 136px
        margin: 8px 0
        display: flex
        align-items: center
        font-size: 30px
        font-family: "Reddit Mono", monospace
        border-radius: 5px
        color: $white


.user
    width: 100%
    &_list
        background:#0000005c
        border: 1px solid #6d6d6d
        border-radius: 5px
        height: auto
        margin: 4px 0 0
        &_title
            display: flex
            flex-direction: row
            
    &_item
        width: 120px
        overflow: hidden
        padding: 2% 2% 0
        margin: 0
        font-size:24px
        color: $white
        font-family: $mainFont
    &_lapCounter
        width: 120px
        display: flex
        align-items: center
.user
    cursor: grab
    &_laps
        grid-column: 1/3
        grid-row: 2/3
        display: flex
        flex-direction: column
        align-items: flex-end

    &_lapsTime
        display: flex
        flex-flow: column wrap
        flex-wrap: wrap
        max-height: 100px
        width: 100%
        overflow-x: auto
        &_el
            display: flex
            height: 18px
            font-family: monospace
            color: $white
            margin-right: 5px
            &_listNumber
                color: rgb(255 255 255 / 50%)
                width: 16px
                text-align: end
                margin-right: 4px
    &_button-lastLap
        display: flex
        height: 35px
        align-items: center
        width: 60px
    &_button-control
        display: flex
        align-items: center
        justify-content: flex-end
