.programsCard {
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
@media (max-width: 500px) {
  .programsCard {
    margin-top: 80px;
  }
}
.programsCard-blocks {
  background: #1d1d1d;
  font-family: "Oswald", sans-serif;
  color: #f1faee;
  width: 430px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
@media (max-width: 500px) {
  .programsCard-blocks {
    width: 80%;
  }
}
.programsCard-blocks:hover {
  cursor: pointer;
}
.programsCard-blocks:hover p {
  color: rgb(173, 255, 0);
}
.programsCard-blocks p {
  font-size: 24px;
  margin-bottom: 30px;
}
.programsCard-blocks p.active {
  color: rgb(173, 255, 0);
}
.programsCard-blocks-information {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 430px;
}
.programsCard-description {
  font-size: 20px;
  width: 80%;
  text-align: center;
  height: 70px;
}
@media (max-width: 500px) {
  .programsCard-description {
    height: 80px;
  }
}
.programsCard-price {
  color: rgb(173, 255, 0);
  font-size: 30px;
  height: 60px;
}
.programsCard-price-input {
  display: none;
}
.programsCard-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 150px;
}
.programsCard-form-cust {
  width: 100%;
  margin-bottom: 10px;
}
.programsCard-form-cust-input {
  width: calc(100% - 4px);
  padding: 2px;
  border: 0;
}
.programsCard-form-btnLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.programsCard-form-product {
  margin-bottom: 10px;
}
.programsCard button {
  background-color: rgb(173, 255, 0);
  width: 250px;
  height: 55px;
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  border: none;
  margin-bottom: 10px;
}
.programsCard button:hover {
  cursor: pointer;
}
@media (max-width: 500px) {
  .programsCard button {
    width: 100%;
  }
}
.programsCard a {
  color: #393939;
  font-size: 20px;
}

