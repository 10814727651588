@import ./variables/variable
@font-face 
    font-family: "Oswald"
    src: url("../fonts/Oswald/Oswald-Light.ttf") format("truetype")
    font-weight: normal
@font-face 
    font-family: "VT323"
    src: url("../fonts/VT323/VT323-Regular.ttf") format("truetype")
    font-weight: normal
@font-face 
    font-family: "Oswald-Bold"
    src: url("../fonts/Oswald/Oswald-Bold.ttf") format("truetype")
    font-weight: bold

html
    scroll-behavior: smooth
    height: 100%
body
    background-color: rgba(36, 36, 36, 1)
    margin: 0
    min-height: 100%
#root
    min-height: 100vh
h1
    font-family: $secondFont
    color: $green
    font-size: 48px
    font-weight: normal
    margin: 0
    @media (max-width: 1170px)
        font-size: 40px
    @media (max-width: 500px)
        font-size: 28px
h2,h3,h4
    font-family: $mainFont
    color: $green
    font-weight: normal

button
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none

.generall_page_wrapper
    display: flex
    flex-direction: column
    align-items: center
    min-height: 100vh
    justify-content: space-between

.first-page
    margin-bottom: 50px
    max-width: 1172px
    @media (max-width: 500px)
        margin-bottom: 50px
.any-page
    background: rgba(29, 29, 29, 1)
    width: 100%
    @media (max-width: 500px)
        margin-bottom: 50px

.main
    width: 100%
    display: grid
    grid-template-columns: repeat(3,1fr)
    column-gap: 2.75% 
    &_page
        grid-column: 1/4
        grid-row: 3/4
        position: relative
        @media (max-width: 500px)
            grid-row: 2/3
        &_pic
            display: flex
            height: 544px
            justify-content: space-between
            margin-bottom: 30px
            div
                width: 32%
                @media (max-width: 500px)
                    padding-top: 4px
            .swimming
                background: center / cover no-repeat url(../pic/swimming.png)
            .cycling
                background: center / cover no-repeat url(../pic/cycling.png)
                box-shadow: $shadowDek
                box-shadow: 6px -6px 0px 0px $green
                @media (max-width: 500px)
                    box-shadow: none
            .running
                background: center / cover no-repeat url(../pic/running.png)

        &_slogan
            position: absolute
            top: 60%
            left: 0
            right: 0
            text-align: center
            font-size: 48px
            @media (max-width: 1000px)
                font-size: 30px
            @media (max-width: 500px)
                font-size: 18px
                top: 50%
            &_wrapper
                background: rgba(36, 36, 36, 1)
                height: fit-content
                margin: 0 auto
                width: 700px
                @media (max-width: 1000px)
                    width: 450px
                @media (max-width: 500px)
                    width: 80%
                h2
                    padding: 2%
                    margin: 0
    &_button
        grid-column: 2/3
        grid-row: 4/5
        height: 62px
        background: $green
        border: none
        font-size: 30px
        font-family: $mainFont
        color: #0f1b2c
        padding: 2%
        cursor: pointer
        transition: all 0.1s ease-out
        @media (min-width: 475px)
            &:hover
                transform: translate(-4px, -6px)
                filter: drop-shadow(4px 6px 0px #d6dfd3)
            &:active
                transform: none
                filter: none
        @media (max-width: 1170px)
            font-size: 24px
        @media (max-width: 740px)
            font-size: 24px
            grid-column: 1/4
            width: 80%
            justify-self: center
        @media (max-width: 500px)
            grid-row: 3/4
            height: 50.5px
.section
    max-width: 1172px
    width: 100%
    display: grid
    grid-template-columns: 7% repeat(3,1fr) 7%
    gap: 0 1.2% 
    margin-bottom: 30px
    @media (max-width:834px) 
        grid-template-columns: 7% repeat(2,1fr) 7%
        gap: 0 3%
    @media (max-width:474px)
        grid-template-columns: 100%
        gap: 0

.asside
    display: flex
    flex-direction: column
    align-items: center
    color: $green
    font-family: $secondFont
    font-size: 48px
    grid-column: 1/2
    grid-row: 1/7
    @media (max-width:579px)
        font-size: 38px
    @media (max-width:474px)
        grid-column: 1/2
        grid-row: 1/2
        flex-direction: row
        justify-content: center
    &_text
        transform: rotate(180deg)
        writing-mode: vertical-lr
        margin-bottom: 30px
        @media (max-width:474px)
            transform: none
            writing-mode: horizontal-tb
            margin-bottom:0
            
    span
        height: 100%
        width: 1px
        background: $green
        @media (max-width:474px)
            width: 100%
            height: 1px
            margin: 0 10px
        &:first-child
            @media (min-width:475px)
                display: none
.about        
    &_question_one
        grid-column: 2/3
        grid-row: 1/3
        align-self: flex-start
        display: flex
        flex-direction: column
        @media (max-width:834px)
            grid-column: 2/3
            grid-row: 1/2
            height: 100%
            justify-content: space-between
        @media (max-width:474px)
            grid-column: 1/2
            grid-row: 2/3
            margin-bottom: 10px
    &_question_two
        grid-column: 3/4
        grid-row: 4/5
        align-self: flex-start
        @media (max-width:834px)
            grid-column: 2/3
            grid-row: 2/3
        @media (max-width:474px)
            grid-column: 1/2
            grid-row: 4/5
    &_question_three
        grid-column: 4/5
        grid-row: 1/2
        align-self: flex-start
        @media (max-width:834px)
            grid-column: 3/4
            grid-row: 2/3
        @media (max-width:474px)
            grid-column: 1/2
            grid-row: 5/6
    &_question_four
        grid-column: 4/5
        grid-row: 2/4
        align-self: flex-start
        @media (max-width:834px)
            grid-column: 3/4
            grid-row: 3/4
        @media (max-width:474px)
            grid-column: 1/2
            grid-row: 7/8
    &_question_five
        grid-column: 4/5
        grid-row: 4/5
        align-self: flex-start
        @media (max-width:834px)
            grid-column: 3/4
            grid-row: 4/5
        @media (max-width:474px)
            grid-column: 1/2
            grid-row: 8/9
    &_phones
        grid-column: 2/3
        grid-row: 3/5
        display: flex
        overflow: hidden
        width: 100%
        position: relative
        align-items: flex-end
        @media (max-width:474px)
            margin:5% 0
            grid-column: 1/2
            grid-row: 6/7
            width: 80%
            justify-self: center
            height: 200px
            align-items: flex-start
        img
            &:first-child
                margin: 0 0 5% 6%
                filter: $shadowDek
                @media (max-width:890px)
                    filter: $shadowMob
                @media (max-width:834px)
                    margin-left: 6%
                @media (max-width:474px)
                    margin: 3px 0 0 0
            &:last-child
                filter: $shadowDek
                position: absolute
                z-index: -1
                right: 5%
                top: 5%
                @media (max-width:890px)
                    filter: $shadowMob
                @media (max-width:474px)
                    top: 3px
                    right: 4px
    &_macBook
        grid-column: 3/4
        grid-row: 1/4
        width: 100%
        height: 440px
        display: flex
        overflow: hidden
        align-self: center
        @media (max-width:834px)
            grid-column: 3/4
            grid-row: 1/2
            height: 400px
        @media (max-width:474px)
            margin:5% 0
            grid-column: 1/2
            grid-row: 3/4
            height: 350px
        img
            filter: $shadowDek
            height: calc( 100% - 6px )
            align-self: flex-end
            @media (max-width:834px)
                filter: $shadowMob
                height: calc( 100% - 4px )
                margin-top: 3px 
    &_gym
        background: no-repeat url('../pic/gym.png')
        background-size: cover
        background-position: center
        grid-column: 2/5
        grid-row: 5/6
        display: flex
        filter: $shadowDek
        margin-top: 10px
        width: calc( 100% - 8px )
        justify-content: center
        align-items: center
        height: 200px
        @media (max-width:834px)
            grid-column: 2/4
            filter: $shadowMob
            width: calc( 100% - 4px )
        @media (max-width:474px)
            grid-column: 1/2
            grid-row: 9/10
            height: 220px
        &_background
            backdrop-filter: blur(2px)
            background: rgba(36, 36, 36, 0.60)
            width: 85%
            height: fit-content
            justify-content: center
            display: flex
        &_text
            font-size: 20px
            text-align: center
            width: 95%
            @media (max-width:474px)
                margin: 2px
    svg
        filter: drop-shadow(2px 3px 0px #ADFF00)
        display: none
        @media (max-width: 834px)
            display: block
            margin-bottom: 5%
        @media (max-width: 524px)
            display: none
    ul
        margin:0 0 5% 0
    li,p
        font-family: $mainFont
        color: $white
        font-size: 20px
        line-height: normal
        margin: 0
        @media (max-width: 1100px)
            font-size: 18px
    p
        @media (max-width:474px)
            padding: 0 2%

    h3
        text-align: center
        font-size: 24px
        margin: 5% 0
        @media (max-width:505px)
            font-size: 20px
.coach
    grid-template-rows: repeat(3, auto) 200px
    position: relative
    @media (max-width:834px)
        grid-template-rows: auto
    &_asside
        @media (max-width:474px)
            grid-column: 1/2
    article,p
        font-family: $mainFont
        color: $white
        font-size: 20px
        line-height: normal
        margin: 0
        @media (max-width: 1096px)
            font-size: 18px
    article
        margin: 5% 0 5% 2%
    &_photo
        grid-column: 2/3
        grid-row: 1/4
        position: relative
        overflow: hidden
        max-height: 500px
        @media (max-width:569px)
            grid-row: 1/3
        @media (max-width:474px)
            grid-column: 1/2
            grid-row: 3/4
            max-height: 600px
        &_background
            filter: $shadowDek
            position: relative
            top: 6px
            width: 100%
        &_face
            position: absolute
            width: 101%
            height: 500px
            left: 0
            top: 0
            background: no-repeat url(../pic/coach.png) top center
            background-size: cover
            @media (max-width:569px)
                height: 400px
            @media (max-width:474px)
                height: 600px
    h2
        grid-column: 3/5
        grid-row: 1/2
        justify-self: flex-start
        margin-left: 1%
        @media (max-width:474px)
            justify-self: center
            grid-column: 1/2
            grid-row: 2/3
    &_first
        grid-column: 3/4
        grid-row: 2/3
        @media (max-width:474px)
            grid-column: 1/2
            grid-row: 5/6
    &_second
        grid-column: 3/4
        grid-row: 3/4
        @media (max-width:474px)
            grid-column: 1/2
            grid-row: 6/7
    &_third
        grid-column: 4/5
        grid-row: 2/3
        @media (max-width:834px)
            grid-column: 3/4
            grid-row: 4/5
        @media (max-width:569px)
            grid-column: 2/3
            grid-row: 3/4
        @media (max-width:474px)
            grid-column: 1/2
            grid-row: 7/8
    &_logo
        grid-column: 4/5
        grid-row: 3/4
        display: flex
        height: 100px
        align-self: flex-start
        flex-direction: column
        justify-content: space-between
        align-items: center
        margin-top: 15px
        @media (max-width:834px)
            grid-column: 2/3
            grid-row: 4/5
            margin: 0px
        @media (max-width:569px)
            grid-row: 2/3
            position: absolute
            width: 37vw
            left: 1.5vw
            top: 80%
            h1
                display: none
        @media (max-width:474px)
            grid-column: 1/2
            left: 0
            grid-row: 3/4
            width: 100%
            height: 60px
            justify-content: center
        &_title
            font-size: 32px
    &_statistics
        grid-column: 2/5
        grid-row: 4/5
        display: grid
        grid-template-columns: repeat(3,1fr)
        column-gap: 5px
        align-items: flex-start
        @media (max-width:834px)
            grid-column: 2/4
            grid-row: 5/6
        div
            text-align: center
        @media (max-width:569px)
            grid-row: 4/5
        @media (max-width:474px)
            grid-column: 1/2
            grid-row: 8/9
    &_number
        font-size: 128px
        font-family: $mainFontBold
        color: $green
        @media (max-width:834px)
            font-size: 90px
.social
    display: flex
    justify-content: space-between
    width: 120px
    @media (max-width:474px)
        width: 160px
    img
        @media (min-width:834px)
            filter: gray
            -webkit-filter: grayscale(1)
            filter: grayscale(1)
            opacity: 0.5
            &:hover
                transition: all 0.2s ease-out
                -webkit-filter: grayscale(0)
                filter: none
                opacity: 1
        @media (max-width:474px)
            width: 60px
            height: 60px

.programs
    &_description
        grid-column: 2/4
        grid-row: 1/2
        height: 150px
        display: flex
        align-items: flex-start
        @media (max-width:834px)
            height: 105px
        @media (max-width:474px)
            grid-row: 2/3
            grid-column: 1/2
            height: auto
            text-align: center
            margin: 5% 0 0
    &_top
        grid-column: 4/5
        grid-row: 1/2
        display: flex
        align-items: center
        justify-content: flex-end
        filter: drop-shadow(2px 3px 0px #ADFF00)
        @media (max-width:834px)
            grid-column: 3/4
            grid-row: 3/4
        @media (max-width:570px)
                display: none
    &_bottom
        filter: drop-shadow(2px 3px 0px #ADFF00)
        grid-column: 2/3
        grid-row: 5/6
        display: flex
        align-items: center
        @media (max-width:834px)
            grid-row: 5/6
            @media (max-width:579px)
                display: none
    &_benefit
        &_types
            grid-column: 2/3
            grid-row: 2/3
            @media (max-width:474px)
                grid-column: 1/2
                grid-row: 3/4
        &_deadlines
            grid-column: 2/3
            grid-row: 3/4
            @media (max-width:834px)
                grid-column: 3/4
                grid-row: 2/3
            @media (max-width:474px)
                grid-column: 1/2
                grid-row: 4/5
        &_goals
            grid-column: 2/3
            grid-row: 4/5
            @media (max-width:834px)
                grid-row: 3/4
            @media (max-width:570px)
                grid-column: 2/4
            @media (max-width:474px)
                grid-column: 1/2
                grid-row: 5/6
    &_feedback
        grid-column: 3/5
        grid-row: 5/6
        display: flex
        text-align: center
        @media (max-width:834px)
            grid-row: 5/6
            grid-column: 3/4
        @media (max-width:579px)
            grid-row: 6/7
            grid-column: 2/4
        @media (max-width:474px)
            grid-column: 1/2
            grid-row: 8/9
            width: 80%
            justify-self: center
        p
            width: 100%
    h4
        font-family: $mainFont
        color: $white
        font-size: 40px
        text-shadow: 1px 3px 0px #ADFF00 
        margin: 0
    h5
        font-family: $mainFont
        color: $green
        font-size: 30px
        margin: 0
    h2
        margin: 0
        @media (max-width:474px)
            margin: 5% 0
            text-align: center
    &_text
        font-family: $mainFont
        color: $white
        font-size: 20px
        @media (max-width:474px)
            text-align: center
            margin: 20px 2%
            font-size: 18px