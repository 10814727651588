@import ../../styles/variables/variable

    
    
.programsCard
    margin-top: 30px
    display: flex
    align-items: center
    flex-direction: column
    width: 100%
    @media (max-width: 500px)
        margin-top: 80px
    &-blocks
        background:#1d1d1d
        font-family: $mainFont
        color: $white
        width: 430px
        padding: 10px
        display: flex
        flex-direction: column
        align-items: center
        justify-content: space-between
        margin-bottom: 30px
        @media (max-width: 500px)
            width: 80%
        &:hover
            cursor: pointer
            & p
                color: $green
        & p
            font-size: 24px
            margin-bottom: 30px
            &.active
                color: $green
        &-information
            display: flex
            flex-direction: column
            align-items: center
            max-width: 430px

    &-description
        font-size: 20px
        width: 80%
        text-align: center
        height: 70px
        @media (max-width: 500px)
            height: 80px
        
    &-price
        color: $green
        font-size: 30px
        height: 60px
        &-input
            display: none

    &-form
        display: flex
        flex-direction: column
        align-items: center
        justify-content: space-between
        height: 150px
        &-cust
            width: 100%
            margin-bottom: 10px
            &-input
                width: calc( 100% - 4px )
                padding: 2px
                border: 0

        &-btnLogo
            display: flex
            flex-direction: column
            align-items: center
            justify-content: space-between
            margin-bottom: 10px
        &-product
            margin-bottom: 10px

    button
        background-color: $green
        width: 250px
        height: 55px
        font-family: $mainFont
        font-size: 20px
        border: none
        margin-bottom: 10px
        &:hover
            cursor: pointer
        @media (max-width: 500px)
            width: 100%
    a
        color: $footerGrey
        font-size: 20px