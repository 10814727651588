@font-face {
  font-family: "Oswald";
  src: url("../fonts/Oswald/Oswald-Light.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "VT323";
  src: url("../fonts/VT323/VT323-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Oswald-Bold";
  src: url("../fonts/Oswald/Oswald-Bold.ttf") format("truetype");
  font-weight: bold;
}
html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  background-color: rgb(36, 36, 36);
  margin: 0;
  min-height: 100%;
}

#root {
  min-height: 100vh;
}

h1 {
  font-family: "VT323", sans-serif;
  color: rgb(173, 255, 0);
  font-size: 48px;
  font-weight: normal;
  margin: 0;
}
@media (max-width: 1170px) {
  h1 {
    font-size: 40px;
  }
}
@media (max-width: 500px) {
  h1 {
    font-size: 28px;
  }
}

h2, h3, h4 {
  font-family: "Oswald", sans-serif;
  color: rgb(173, 255, 0);
  font-weight: normal;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.generall_page_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: space-between;
}

.first-page {
  margin-bottom: 50px;
  max-width: 1172px;
}
@media (max-width: 500px) {
  .first-page {
    margin-bottom: 50px;
  }
}

.any-page {
  background: rgb(29, 29, 29);
  width: 100%;
}
@media (max-width: 500px) {
  .any-page {
    margin-bottom: 50px;
  }
}

.main {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2.75%;
}
.main_page {
  grid-column: 1/4;
  grid-row: 3/4;
  position: relative;
}
@media (max-width: 500px) {
  .main_page {
    grid-row: 2/3;
  }
}
.main_page_pic {
  display: flex;
  height: 544px;
  justify-content: space-between;
  margin-bottom: 30px;
}
.main_page_pic div {
  width: 32%;
}
@media (max-width: 500px) {
  .main_page_pic div {
    padding-top: 4px;
  }
}
.main_page_pic .swimming {
  background: center/cover no-repeat url(../pic/swimming.png);
}
.main_page_pic .cycling {
  background: center/cover no-repeat url(../pic/cycling.png);
  box-shadow: drop-shadow(8px -6px 0px #ADFF00);
  box-shadow: 6px -6px 0px 0px rgb(173, 255, 0);
}
@media (max-width: 500px) {
  .main_page_pic .cycling {
    box-shadow: none;
  }
}
.main_page_pic .running {
  background: center/cover no-repeat url(../pic/running.png);
}
.main_page_slogan {
  position: absolute;
  top: 60%;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 48px;
}
@media (max-width: 1000px) {
  .main_page_slogan {
    font-size: 30px;
  }
}
@media (max-width: 500px) {
  .main_page_slogan {
    font-size: 18px;
    top: 50%;
  }
}
.main_page_slogan_wrapper {
  background: rgb(36, 36, 36);
  height: fit-content;
  margin: 0 auto;
  width: 700px;
}
@media (max-width: 1000px) {
  .main_page_slogan_wrapper {
    width: 450px;
  }
}
@media (max-width: 500px) {
  .main_page_slogan_wrapper {
    width: 80%;
  }
}
.main_page_slogan_wrapper h2 {
  padding: 2%;
  margin: 0;
}
.main_button {
  grid-column: 2/3;
  grid-row: 4/5;
  height: 62px;
  background: rgb(173, 255, 0);
  border: none;
  font-size: 30px;
  font-family: "Oswald", sans-serif;
  color: #0f1b2c;
  padding: 2%;
  cursor: pointer;
  transition: all 0.1s ease-out;
}
@media (min-width: 475px) {
  .main_button:hover {
    transform: translate(-4px, -6px);
    filter: drop-shadow(4px 6px 0px #d6dfd3);
  }
  .main_button:active {
    transform: none;
    filter: none;
  }
}
@media (max-width: 1170px) {
  .main_button {
    font-size: 24px;
  }
}
@media (max-width: 740px) {
  .main_button {
    font-size: 24px;
    grid-column: 1/4;
    width: 80%;
    justify-self: center;
  }
}
@media (max-width: 500px) {
  .main_button {
    grid-row: 3/4;
    height: 50.5px;
  }
}

.section {
  max-width: 1172px;
  width: 100%;
  display: grid;
  grid-template-columns: 7% repeat(3, 1fr) 7%;
  gap: 0 1.2%;
  margin-bottom: 30px;
}
@media (max-width: 834px) {
  .section {
    grid-template-columns: 7% repeat(2, 1fr) 7%;
    gap: 0 3%;
  }
}
@media (max-width: 474px) {
  .section {
    grid-template-columns: 100%;
    gap: 0;
  }
}

.asside {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(173, 255, 0);
  font-family: "VT323", sans-serif;
  font-size: 48px;
  grid-column: 1/2;
  grid-row: 1/7;
}
@media (max-width: 579px) {
  .asside {
    font-size: 38px;
  }
}
@media (max-width: 474px) {
  .asside {
    grid-column: 1/2;
    grid-row: 1/2;
    flex-direction: row;
    justify-content: center;
  }
}
.asside_text {
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  margin-bottom: 30px;
}
@media (max-width: 474px) {
  .asside_text {
    transform: none;
    writing-mode: horizontal-tb;
    margin-bottom: 0;
  }
}
.asside span {
  height: 100%;
  width: 1px;
  background: rgb(173, 255, 0);
}
@media (max-width: 474px) {
  .asside span {
    width: 100%;
    height: 1px;
    margin: 0 10px;
  }
}
@media (min-width: 475px) {
  .asside span:first-child {
    display: none;
  }
}

.about_question_one {
  grid-column: 2/3;
  grid-row: 1/3;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
}
@media (max-width: 834px) {
  .about_question_one {
    grid-column: 2/3;
    grid-row: 1/2;
    height: 100%;
    justify-content: space-between;
  }
}
@media (max-width: 474px) {
  .about_question_one {
    grid-column: 1/2;
    grid-row: 2/3;
    margin-bottom: 10px;
  }
}
.about_question_two {
  grid-column: 3/4;
  grid-row: 4/5;
  align-self: flex-start;
}
@media (max-width: 834px) {
  .about_question_two {
    grid-column: 2/3;
    grid-row: 2/3;
  }
}
@media (max-width: 474px) {
  .about_question_two {
    grid-column: 1/2;
    grid-row: 4/5;
  }
}
.about_question_three {
  grid-column: 4/5;
  grid-row: 1/2;
  align-self: flex-start;
}
@media (max-width: 834px) {
  .about_question_three {
    grid-column: 3/4;
    grid-row: 2/3;
  }
}
@media (max-width: 474px) {
  .about_question_three {
    grid-column: 1/2;
    grid-row: 5/6;
  }
}
.about_question_four {
  grid-column: 4/5;
  grid-row: 2/4;
  align-self: flex-start;
}
@media (max-width: 834px) {
  .about_question_four {
    grid-column: 3/4;
    grid-row: 3/4;
  }
}
@media (max-width: 474px) {
  .about_question_four {
    grid-column: 1/2;
    grid-row: 7/8;
  }
}
.about_question_five {
  grid-column: 4/5;
  grid-row: 4/5;
  align-self: flex-start;
}
@media (max-width: 834px) {
  .about_question_five {
    grid-column: 3/4;
    grid-row: 4/5;
  }
}
@media (max-width: 474px) {
  .about_question_five {
    grid-column: 1/2;
    grid-row: 8/9;
  }
}
.about_phones {
  grid-column: 2/3;
  grid-row: 3/5;
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;
  align-items: flex-end;
}
@media (max-width: 474px) {
  .about_phones {
    margin: 5% 0;
    grid-column: 1/2;
    grid-row: 6/7;
    width: 80%;
    justify-self: center;
    height: 200px;
    align-items: flex-start;
  }
}
.about_phones img:first-child {
  margin: 0 0 5% 6%;
  filter: drop-shadow(8px -6px 0px #ADFF00);
}
@media (max-width: 890px) {
  .about_phones img:first-child {
    filter: drop-shadow(4px -2px 0px #ADFF00);
  }
}
@media (max-width: 834px) {
  .about_phones img:first-child {
    margin-left: 6%;
  }
}
@media (max-width: 474px) {
  .about_phones img:first-child {
    margin: 3px 0 0 0;
  }
}
.about_phones img:last-child {
  filter: drop-shadow(8px -6px 0px #ADFF00);
  position: absolute;
  z-index: -1;
  right: 5%;
  top: 5%;
}
@media (max-width: 890px) {
  .about_phones img:last-child {
    filter: drop-shadow(4px -2px 0px #ADFF00);
  }
}
@media (max-width: 474px) {
  .about_phones img:last-child {
    top: 3px;
    right: 4px;
  }
}
.about_macBook {
  grid-column: 3/4;
  grid-row: 1/4;
  width: 100%;
  height: 440px;
  display: flex;
  overflow: hidden;
  align-self: center;
}
@media (max-width: 834px) {
  .about_macBook {
    grid-column: 3/4;
    grid-row: 1/2;
    height: 400px;
  }
}
@media (max-width: 474px) {
  .about_macBook {
    margin: 5% 0;
    grid-column: 1/2;
    grid-row: 3/4;
    height: 350px;
  }
}
.about_macBook img {
  filter: drop-shadow(8px -6px 0px #ADFF00);
  height: calc(100% - 6px);
  align-self: flex-end;
}
@media (max-width: 834px) {
  .about_macBook img {
    filter: drop-shadow(4px -2px 0px #ADFF00);
    height: calc(100% - 4px);
    margin-top: 3px;
  }
}
.about_gym {
  background: no-repeat url("../pic/gym.png");
  background-size: cover;
  background-position: center;
  grid-column: 2/5;
  grid-row: 5/6;
  display: flex;
  filter: drop-shadow(8px -6px 0px #ADFF00);
  margin-top: 10px;
  width: calc(100% - 8px);
  justify-content: center;
  align-items: center;
  height: 200px;
}
@media (max-width: 834px) {
  .about_gym {
    grid-column: 2/4;
    filter: drop-shadow(4px -2px 0px #ADFF00);
    width: calc(100% - 4px);
  }
}
@media (max-width: 474px) {
  .about_gym {
    grid-column: 1/2;
    grid-row: 9/10;
    height: 220px;
  }
}
.about_gym_background {
  backdrop-filter: blur(2px);
  background: rgba(36, 36, 36, 0.6);
  width: 85%;
  height: fit-content;
  justify-content: center;
  display: flex;
}
.about_gym_text {
  font-size: 20px;
  text-align: center;
  width: 95%;
}
@media (max-width: 474px) {
  .about_gym_text {
    margin: 2px;
  }
}
.about svg {
  filter: drop-shadow(2px 3px 0px #ADFF00);
  display: none;
}
@media (max-width: 834px) {
  .about svg {
    display: block;
    margin-bottom: 5%;
  }
}
@media (max-width: 524px) {
  .about svg {
    display: none;
  }
}
.about ul {
  margin: 0 0 5% 0;
}
.about li, .about p {
  font-family: "Oswald", sans-serif;
  color: #f1faee;
  font-size: 20px;
  line-height: normal;
  margin: 0;
}
@media (max-width: 1100px) {
  .about li, .about p {
    font-size: 18px;
  }
}
@media (max-width: 474px) {
  .about p {
    padding: 0 2%;
  }
}
.about h3 {
  text-align: center;
  font-size: 24px;
  margin: 5% 0;
}
@media (max-width: 505px) {
  .about h3 {
    font-size: 20px;
  }
}

.coach {
  grid-template-rows: repeat(3, auto) 200px;
  position: relative;
}
@media (max-width: 834px) {
  .coach {
    grid-template-rows: auto;
  }
}
@media (max-width: 474px) {
  .coach_asside {
    grid-column: 1/2;
  }
}
.coach article, .coach p {
  font-family: "Oswald", sans-serif;
  color: #f1faee;
  font-size: 20px;
  line-height: normal;
  margin: 0;
}
@media (max-width: 1096px) {
  .coach article, .coach p {
    font-size: 18px;
  }
}
.coach article {
  margin: 5% 0 5% 2%;
}
.coach_photo {
  grid-column: 2/3;
  grid-row: 1/4;
  position: relative;
  overflow: hidden;
  max-height: 500px;
}
@media (max-width: 569px) {
  .coach_photo {
    grid-row: 1/3;
  }
}
@media (max-width: 474px) {
  .coach_photo {
    grid-column: 1/2;
    grid-row: 3/4;
    max-height: 600px;
  }
}
.coach_photo_background {
  filter: drop-shadow(8px -6px 0px #ADFF00);
  position: relative;
  top: 6px;
  width: 100%;
}
.coach_photo_face {
  position: absolute;
  width: 101%;
  height: 500px;
  left: 0;
  top: 0;
  background: no-repeat url(../pic/coach.png) top center;
  background-size: cover;
}
@media (max-width: 569px) {
  .coach_photo_face {
    height: 400px;
  }
}
@media (max-width: 474px) {
  .coach_photo_face {
    height: 600px;
  }
}
.coach h2 {
  grid-column: 3/5;
  grid-row: 1/2;
  justify-self: flex-start;
  margin-left: 1%;
}
@media (max-width: 474px) {
  .coach h2 {
    justify-self: center;
    grid-column: 1/2;
    grid-row: 2/3;
  }
}
.coach_first {
  grid-column: 3/4;
  grid-row: 2/3;
}
@media (max-width: 474px) {
  .coach_first {
    grid-column: 1/2;
    grid-row: 5/6;
  }
}
.coach_second {
  grid-column: 3/4;
  grid-row: 3/4;
}
@media (max-width: 474px) {
  .coach_second {
    grid-column: 1/2;
    grid-row: 6/7;
  }
}
.coach_third {
  grid-column: 4/5;
  grid-row: 2/3;
}
@media (max-width: 834px) {
  .coach_third {
    grid-column: 3/4;
    grid-row: 4/5;
  }
}
@media (max-width: 569px) {
  .coach_third {
    grid-column: 2/3;
    grid-row: 3/4;
  }
}
@media (max-width: 474px) {
  .coach_third {
    grid-column: 1/2;
    grid-row: 7/8;
  }
}
.coach_logo {
  grid-column: 4/5;
  grid-row: 3/4;
  display: flex;
  height: 100px;
  align-self: flex-start;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
@media (max-width: 834px) {
  .coach_logo {
    grid-column: 2/3;
    grid-row: 4/5;
    margin: 0px;
  }
}
@media (max-width: 569px) {
  .coach_logo {
    grid-row: 2/3;
    position: absolute;
    width: 37vw;
    left: 1.5vw;
    top: 80%;
  }
  .coach_logo h1 {
    display: none;
  }
}
@media (max-width: 474px) {
  .coach_logo {
    grid-column: 1/2;
    left: 0;
    grid-row: 3/4;
    width: 100%;
    height: 60px;
    justify-content: center;
  }
}
.coach_logo_title {
  font-size: 32px;
}
.coach_statistics {
  grid-column: 2/5;
  grid-row: 4/5;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 5px;
  align-items: flex-start;
}
@media (max-width: 834px) {
  .coach_statistics {
    grid-column: 2/4;
    grid-row: 5/6;
  }
}
.coach_statistics div {
  text-align: center;
}
@media (max-width: 569px) {
  .coach_statistics {
    grid-row: 4/5;
  }
}
@media (max-width: 474px) {
  .coach_statistics {
    grid-column: 1/2;
    grid-row: 8/9;
  }
}
.coach_number {
  font-size: 128px;
  font-family: "Oswald-Bold", sans-serif;
  color: rgb(173, 255, 0);
}
@media (max-width: 834px) {
  .coach_number {
    font-size: 90px;
  }
}

.social {
  display: flex;
  justify-content: space-between;
  width: 120px;
}
@media (max-width: 474px) {
  .social {
    width: 160px;
  }
}
@media (min-width: 834px) {
  .social img {
    filter: gray;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    opacity: 0.5;
  }
  .social img:hover {
    transition: all 0.2s ease-out;
    -webkit-filter: grayscale(0);
    filter: none;
    opacity: 1;
  }
}
@media (max-width: 474px) {
  .social img {
    width: 60px;
    height: 60px;
  }
}

.programs_description {
  grid-column: 2/4;
  grid-row: 1/2;
  height: 150px;
  display: flex;
  align-items: flex-start;
}
@media (max-width: 834px) {
  .programs_description {
    height: 105px;
  }
}
@media (max-width: 474px) {
  .programs_description {
    grid-row: 2/3;
    grid-column: 1/2;
    height: auto;
    text-align: center;
    margin: 5% 0 0;
  }
}
.programs_top {
  grid-column: 4/5;
  grid-row: 1/2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  filter: drop-shadow(2px 3px 0px #ADFF00);
}
@media (max-width: 834px) {
  .programs_top {
    grid-column: 3/4;
    grid-row: 3/4;
  }
}
@media (max-width: 570px) {
  .programs_top {
    display: none;
  }
}
.programs_bottom {
  filter: drop-shadow(2px 3px 0px #ADFF00);
  grid-column: 2/3;
  grid-row: 5/6;
  display: flex;
  align-items: center;
}
@media (max-width: 834px) {
  .programs_bottom {
    grid-row: 5/6;
  }
}
@media (max-width: 834px) and (max-width: 579px) {
  .programs_bottom {
    display: none;
  }
}
.programs_benefit_types {
  grid-column: 2/3;
  grid-row: 2/3;
}
@media (max-width: 474px) {
  .programs_benefit_types {
    grid-column: 1/2;
    grid-row: 3/4;
  }
}
.programs_benefit_deadlines {
  grid-column: 2/3;
  grid-row: 3/4;
}
@media (max-width: 834px) {
  .programs_benefit_deadlines {
    grid-column: 3/4;
    grid-row: 2/3;
  }
}
@media (max-width: 474px) {
  .programs_benefit_deadlines {
    grid-column: 1/2;
    grid-row: 4/5;
  }
}
.programs_benefit_goals {
  grid-column: 2/3;
  grid-row: 4/5;
}
@media (max-width: 834px) {
  .programs_benefit_goals {
    grid-row: 3/4;
  }
}
@media (max-width: 570px) {
  .programs_benefit_goals {
    grid-column: 2/4;
  }
}
@media (max-width: 474px) {
  .programs_benefit_goals {
    grid-column: 1/2;
    grid-row: 5/6;
  }
}
.programs_feedback {
  grid-column: 3/5;
  grid-row: 5/6;
  display: flex;
  text-align: center;
}
@media (max-width: 834px) {
  .programs_feedback {
    grid-row: 5/6;
    grid-column: 3/4;
  }
}
@media (max-width: 579px) {
  .programs_feedback {
    grid-row: 6/7;
    grid-column: 2/4;
  }
}
@media (max-width: 474px) {
  .programs_feedback {
    grid-column: 1/2;
    grid-row: 8/9;
    width: 80%;
    justify-self: center;
  }
}
.programs_feedback p {
  width: 100%;
}
.programs h4 {
  font-family: "Oswald", sans-serif;
  color: #f1faee;
  font-size: 40px;
  text-shadow: 1px 3px 0px #ADFF00;
  margin: 0;
}
.programs h5 {
  font-family: "Oswald", sans-serif;
  color: rgb(173, 255, 0);
  font-size: 30px;
  margin: 0;
}
.programs h2 {
  margin: 0;
}
@media (max-width: 474px) {
  .programs h2 {
    margin: 5% 0;
    text-align: center;
  }
}
.programs_text {
  font-family: "Oswald", sans-serif;
  color: #f1faee;
  font-size: 20px;
}
@media (max-width: 474px) {
  .programs_text {
    text-align: center;
    margin: 20px 2%;
    font-size: 18px;
  }
}

