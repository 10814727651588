@import url("https://fonts.googleapis.com/css2?family=Reddit+Mono:wght@200..900&family=Share+Tech+Mono&display=swap");
.timer {
  margin: 100px 2%;
  padding: 20px 0;
  max-width: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #1d1d1d;
  border-radius: 5px;
}

.group_list {
  border: 2px solid;
  border-radius: 5px;
  font-family: "Reddit Mono", monospace;
  color: #b3b3b3;
  font-size: 15px;
  margin-bottom: 2px;
}
.group_list p {
  margin: 0;
  padding: 2%;
}
.group_wrapper {
  display: flex;
  justify-content: space-between;
  padding: 5px 5px 0;
}
.group_button {
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: center;
  width: 60px;
  border-radius: 5px;
  border: 1px solid;
  background: #f1faee;
  color: #393939;
}
.group_name {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.group_item {
  width: 80px;
}

.input_wrapper {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input_block {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input_group {
  width: 8%;
}
.input_name {
  width: 60%;
}
.input_group, .input_name {
  height: 30px;
  font-size: 20px;
  border: 0;
  margin: 0 1px;
  padding: 0;
  border-radius: 5px;
}

.button {
  height: 30px;
  width: 50px;
  border: 1px solid;
  padding: 0;
  border-radius: 5px;
  margin: 0 2px;
  transform: none;
  filter: drop-shadow(2px 2px 0px rgb(173, 255, 0));
  font-family: "Oswald", sans-serif;
  color: #161616;
}
.button:active {
  transform: translate(2px, 2px);
  filter: none;
  transition: all 0.1s ease-out;
}
@media (min-width: 360px) {
  .button {
    width: 60px;
  }
}
.button_block {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
.button_lap {
  margin-right: 5px;
}
.button_lastLap {
  justify-self: flex-end;
}
.button_add {
  width: 120px;
}

.stopwatch {
  display: flex;
  flex-direction: column;
  padding: 0 2% 2%;
}
.stopwatch_wrapper {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
}

.display {
  font-size: 70px;
  font-family: "Reddit Mono", monospace;
  grid-column: 1/3;
  justify-content: center;
  justify-self: center;
  color: rgb(173, 255, 0);
  margin-bottom: 10px;
}
.display_user {
  height: 38px;
  width: 136px;
  margin: 8px 0;
  display: flex;
  align-items: center;
  font-size: 30px;
  font-family: "Reddit Mono", monospace;
  border-radius: 5px;
  color: #f1faee;
}

.user {
  width: 100%;
}
.user_list {
  background: rgba(0, 0, 0, 0.3607843137);
  border: 1px solid #6d6d6d;
  border-radius: 5px;
  height: auto;
  margin: 4px 0 0;
}
.user_list_title {
  display: flex;
  flex-direction: row;
}
.user_item {
  width: 120px;
  overflow: hidden;
  padding: 2% 2% 0;
  margin: 0;
  font-size: 24px;
  color: #f1faee;
  font-family: "Oswald", sans-serif;
}
.user_lapCounter {
  width: 120px;
  display: flex;
  align-items: center;
}

.user {
  cursor: grab;
}
.user_laps {
  grid-column: 1/3;
  grid-row: 2/3;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.user_lapsTime {
  display: flex;
  flex-flow: column wrap;
  flex-wrap: wrap;
  max-height: 100px;
  width: 100%;
  overflow-x: auto;
}
.user_lapsTime_el {
  display: flex;
  height: 18px;
  font-family: monospace;
  color: #f1faee;
  margin-right: 5px;
}
.user_lapsTime_el_listNumber {
  color: rgba(255, 255, 255, 0.5);
  width: 16px;
  text-align: end;
  margin-right: 4px;
}
.user_button-lastLap {
  display: flex;
  height: 35px;
  align-items: center;
  width: 60px;
}
.user_button-control {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

