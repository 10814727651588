.footer {
  width: 100%;
  background: #1d1d1d;
  height: 200px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-family: "VT323", sans-serif;
  color: #393939;
  font-size: 24px;
  align-self: flex-end;
}
@media (max-width: 474px) {
  .footer {
    height: 270px;
  }
}
.footer_block {
  height: 130px;
  margin: 0 10px;
}
@media (max-width: 474px) {
  .footer_block {
    height: 100%;
  }
}
.footer_social {
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 474px) {
  .footer_social {
    grid-column: 1/2;
    grid-row: 1/2;
    height: 140px;
    justify-content: space-around;
  }
}
.footer_contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 474px) {
  .footer_contact {
    justify-content: space-evenly;
    height: 75px;
  }
}
.footer_contact a {
  text-decoration: none;
  margin-right: 10px;
  color: #393939;
}
@media (min-width: 835px) {
  .footer_contact a:hover {
    transition: all 0.2s ease-out;
    color: #f1faee;
  }
}
.footer_contact_mail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 230px;
}
.footer_contact_mail:hover {
  transition: all 0.2s ease-out;
  color: #f1faee;
}
.footer_title {
  color: #393939;
  font-size: 40px;
}
@media (max-width: 474px) {
  .footer_title {
    text-align: center;
    font-size: 30px;
  }
}
.footer_right-side {
  margin-top: 12px;
  grid-column: 4/6;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
}
@media (max-width: 834px) {
  .footer_right-side {
    grid-column: 3/5;
  }
}
@media (max-width: 474px) {
  .footer_right-side {
    justify-content: flex-end;
    grid-column: 1/2;
    grid-row: 2/3;
    justify-self: center;
    width: 230px;
    height: 105px;
    align-items: center;
    margin-top: 0;
  }
}
.footer_right-side_wrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
@media (max-width: 474px) {
  .footer_right-side_wrapper {
    align-items: center;
  }
}
.footer_right-side a {
  text-decoration: none;
  color: inherit;
}

.doc, .doc_title {
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  color: #393939;
  margin: 0;
}
@media (max-width: 474px) {
  .doc, .doc_title {
    font-size: 18px;
  }
}

.doc {
  text-decoration: none;
}
.doc:hover {
  transition: all 0.2s ease-out;
  color: #f1faee;
}
.doc_title {
  text-align: end;
}
@media (max-width: 474px) {
  .doc_title {
    text-align: center;
    padding-bottom: 5px;
  }
}

