@import ../styles/variables/variable

.modal
    height: 100vh
    width: 100vw
    background-color: rgba(0, 0, 0, 0.60)
    position: fixed
    top: 0
    left: 0
    display: flex
    align-items: center
    justify-content: center
    opacity: 0
    pointer-events: none
    transition: 0.3s all
    font-family: $mainFont
    z-index: 2
    & p
        margin: 0
    &_close
        height: 40px
        width: 40px
        display: flex
        justify-content: center
        position: relative
        background: rgba(29, 29, 29, 0.5)
        border-radius: 20px
        margin-top: 10px
        margin-right: 10px
        @media (max-width: 440px)
            margin-top: 5px
            margin-right: 5px
        & span:last-child
            transform: rotate(135deg)
        & span
            position: absolute
            display: flex
            width: 28px
            height: 4px
            background: $green
            transform: rotate(45deg)
            translate: 0 18px
        &:hover
            cursor: pointer
    &_content
        width: 50vw
        border-radius: 5px
        background-color: white
        transform: scale(0.8)
        transition: 0.1s all
        background: rgba(29, 29, 29, 1)
        color: $white
        @media (max-width: 1000px)
            width: 80vw
        @media (max-width: 400px)
            width: 95vw
        &.active
            transform: scale(1)
        &_header
            display: flex
            height: 150px
            width: 100%
            justify-content: flex-start
            align-items: flex-end
            background-blend-mode: multiply
            margin-bottom: 22px
            flex-direction: column
            
            &.triathlon
                background: center / cover no-repeat url(../pic/triathlon-card-background.png),rgba(36, 36, 36, 0.25)
            &.multisport
                background: center / cover no-repeat url(../pic/multisport-card-background.png),rgba(36, 36, 36, 0.25)
            & p
                padding-top: 20px
                font-size: 40px
                color: $white
                align-self: center
                filter: drop-shadow(1px 1px $footerFontColor)
        &_tumbler
            display: flex
            justify-content: center
            font-family: $secondFont
            margin-bottom: 22px
            &_el
                border: 1px $green solid
                font-size: 25px
                width: 65px
                text-align: center
                cursor: pointer
                &.active
                    background: $green
                    color: #000
        &_wrapper
            display: flex
            flex-direction: column
            align-items: center
        &_description
            margin-bottom: 22px
            width: 95%
            text-align: center
        &_advantages
            width: 350px
            min-height: 215px
            @media (max-width: 440px)
                width: 95%
            & p
                text-align: center
                color: $green
            & li
                padding-bottom: 5px
        &_price
            color: $green
            font-size: 30px
        &_acceptPolicy
            display: flex
            height: 85px
            align-items: center
            align-content: space-around
            width: 95%
            justify-content: center
            flex-wrap: wrap
            margin: 10px 0
            & div
                text-align: center
            & label, input
                cursor: pointer
            input
                margin-right: 8px
            & a
                color: $white
        &_button
            width: 200px
            height: 55px
            padding: 0
            font-family: $mainFont
            font-size: 20px
            margin-bottom: 20px
            border: none
            background: $footerGrey
            cursor: not-allowed
            @media (max-width: 250px)
                width: 90%
            &.active
                background: $green
                cursor: pointer
    &.active
        opacity: 1
        pointer-events: all

