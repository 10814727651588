@import ../../styles/variables/variable
.menu.active
    transform: translateY(0)
    position: fixed

.menu
    list-style: none
    display: flex
    justify-content: space-between
    flex-direction: row
    align-self: center
    margin: 0
    padding: 0
    width: 650px
    @media (max-width: 850px)
        width: 70vw
    @media (max-width: 500px)
        position: absolute
        width: 100vw
        height: 320px
        flex-direction: column
        justify-content: flex-end
        align-items: center
        background: rgba(29, 29, 29, 1)
        top: -20px
        left: 0 
        transform: translateY(-120%)
        transition: all 0.2s ease-out
        padding-bottom: 10px
    li
        font-family: $secondFont
        color: $green
        font-size: 30px
        @media (max-width: 740px)
            font-size: 25px
        @media (max-width: 500px)
            margin: 15px
            font-size: 30px
        a
            color: $green
            text-decoration: none
        @media (min-width: 501px)
            &:hover
                transition: all 0.1s ease-out
                cursor: pointer
                filter: drop-shadow(2px 1px 0px #f1faee)
                @media (max-width: 500px)