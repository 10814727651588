nav {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 2;
  padding-top: 20px;
  margin-bottom: 30px;
}
@media (max-width: 500px) {
  nav {
    background: rgb(29, 29, 29);
    justify-content: center;
    width: 100%;
    padding: 0;
    height: 50px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    margin: 0;
  }
}
nav.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.header-back {
  display: flex;
  justify-content: center;
  width: 300px;
}
@media (max-width: 500px) {
  .header-back {
    width: 120px;
    margin: 0 5px;
    justify-content: flex-end;
  }
}
.header-back button {
  font-family: "VT323", sans-serif;
  font-size: 30px;
  color: rgb(173, 255, 0);
  background: none;
  background: rgb(57, 57, 57);
}
.header-back button:last-child {
  margin-left: 25px;
}
@media (max-width: 500px) {
  .header-back button:last-child {
    margin-left: 5px;
  }
}
.header-back button:hover {
  cursor: pointer;
}
@media (max-width: 500px) {
  .header-back button {
    font-size: 24px;
  }
}

.title {
  text-align: center;
  margin-bottom: 30px;
}
@media (max-width: 500px) {
  .title {
    font-size: 25px;
    margin: 0 5px;
    text-align: left;
    vertical-align: middle;
    z-index: 2;
  }
}

.burger {
  display: none;
}
@media (max-width: 500px) {
  .burger {
    z-index: 2;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 5px;
  }
  .burger span {
    width: 32px;
    height: 4px;
    background: rgb(173, 255, 0);
  }
}

.burger.close {
  position: relative;
  top: 5px;
  right: 7px;
  rotate: 45deg;
}
.burger.close span {
  width: 28px;
}
.burger.close span:last-child {
  visibility: hidden;
}
.burger.close span:first-child {
  position: absolute;
  rotate: 90deg;
}

